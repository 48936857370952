//animation
@transition-speed: 0.25s;

// Media Queries e.g., @media @sm-up {  }
//xs: default is phone portrait, no need to specify with query
@sm-up: ~"only screen and (min-width: 544px)"; //phone landscape
@md-up: ~"only screen and (min-width: 768px)"; //tablet portrait
@lg-up: ~"only screen and (min-width: 992px)"; //tablet landscape
@xl-up: ~"only screen and (min-width: 1200px)"; //desktop +

.sans {
	font-family: 'Open Sans', sans-serif;
}